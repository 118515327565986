import moment from 'moment';

class PublicUserProfile {
  created_at;
  isAnalyticsPagePublic;
  name;
  photoURL;
  subscriptionStatus;
  subscriptionTierName;
  tweetMetricsUpdatedAt;
  username;
  uid;

  constructor(user) {
    this.created_at = user.created_at;
    this.isAnalyticsPagePublic = user.isAnalyticsPagePublic ? user.isAnalyticsPagePublic : false;
    this.name = user.name;
    this.photoURL = user.photoURL;
    this.subscriptionStatus = user.subscriptionStatus;
    this.customerStatus = user.subscriptionStatus === 'active' ? user.subscriptionTierName.toLowerCase() : 'none';
    this.subscriptionTierName = user.subscriptionTierName;
    this.tweetMetricsUpdatedAt = user.tweetMetricsUpdatedAt;
    this.username = user.username;
    this.uid = user.uid;
  }

  createdAt() {
    const createdAt = this.created_at ? this.created_at.toDate() : new Date();
    return moment(createdAt);
  }
}

export { PublicUserProfile };
