<template>
  <div>
    <!-- non completed new Design  -->
    <div v-if="false">
      <div class="py-10 px-8">
        <div class="mx-auto max-w-610">
          <h1 class="mb-10 text-32 font-bold">Analytics test</h1>
          <div class="mb-8 grid grid-cols-3 gap-6">
            <div
              class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
            >
              <span class="text-lg text-gray-80">Tweets</span>
              <div class="mb-1 flex items-center gap-5">
                <span class="text-2xl font-bold">19</span>
                <span class="flex items-center text-lg text-danger-100">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0465 9.61984L7.99979 13.6665L3.95312 9.61984"
                      stroke="#FF3C3C"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 2.33322V13.5532"
                      stroke="#FF3C3C"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  31.1%
                </span>
              </div>
              <div class="flex items-center justify-center">
                <svg
                  width="128"
                  height="21"
                  viewBox="0 0 128 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 21C5.53222 17.9384 19.6607 12.0192 31.9167 12.8356C47.2367 13.8562 47.875 17.4283 54.8967 17.4283C68.911 17.4283 72.1357 11.9252 79.1533 8.24342C85.9622 4.6712 99.0544 8.24342 107.24 8.24342C115.326 8.24341 119.156 -0.941388 127.667 0.0791567"
                    stroke="#E2E2E2"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
            <div
              class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
            >
              <span class="text-lg text-gray-80">Impressions</span>
              <div class="mb-1 flex items-center gap-5">
                <span class="text-2xl font-bold">24.1k</span>
                <span class="flex items-center text-lg text-danger-100">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0465 9.61984L7.99979 13.6665L3.95312 9.61984"
                      stroke="#FF3C3C"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 2.33322V13.5532"
                      stroke="#FF3C3C"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  31.1%
                </span>
              </div>
              <div class="flex items-center justify-center">
                <svg
                  width="128"
                  height="21"
                  viewBox="0 0 128 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 21C5.53222 17.9384 19.6607 12.0192 31.9167 12.8356C47.2367 13.8562 47.875 17.4283 54.8967 17.4283C68.911 17.4283 72.1357 11.9252 79.1533 8.24342C85.9622 4.6712 99.0544 8.24342 107.24 8.24342C115.326 8.24341 119.156 -0.941388 127.667 0.0791567"
                    stroke="#E2E2E2"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
            <div
              class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
            >
              <span class="text-lg text-gray-80">Profile Clicks</span>
              <div class="mb-1 flex items-center gap-5">
                <span class="text-2xl font-bold">273</span>
                <span class="flex items-center text-lg text-success-100">
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.38 6.38016L8.33329 2.3335L4.28662 6.38016"
                      stroke="#28AF87"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.3335 13.6668V2.44678"
                      stroke="#28AF87"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  16.1%
                </span>
              </div>
              <div class="flex items-center justify-center">
                <svg
                  width="128"
                  height="21"
                  viewBox="0 0 128 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 21C5.53222 17.9384 19.6607 12.0192 31.9167 12.8356C47.2367 13.8562 47.875 17.4283 54.8967 17.4283C68.911 17.4283 72.1357 11.9252 79.1533 8.24342C85.9622 4.6712 99.0544 8.24342 107.24 8.24342C115.326 8.24341 119.156 -0.941388 127.667 0.0791567"
                    stroke="#E2E2E2"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="mb-8 flex items-center gap-8">
            <span class="inline-flex cursor-pointer items-center rounded-3xl text-lg text-gray-80"
              >7 days</span
            >
            <span
              class="inline-flex cursor-pointer items-center rounded-3xl bg-main-color-50 px-3 py-2 text-lg text-main-color-100"
              >14 days</span
            >
            <span class="inline-flex cursor-pointer items-center rounded-3xl text-lg text-gray-80"
              >1 month</span
            >
            <span class="inline-flex cursor-pointer items-center rounded-3xl text-lg text-gray-80"
              >3 months</span
            >
          </div>
          <div class="grid gap-8">
            <div
              class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
            >
              <div class="flex items-center justify-between">
                <div class="grid gap-1">
                  <span class="text-lg text-gray-80">Followers</span>
                  <span class="flex items-center text-2xl font-bold"
                    >10,524 <span class="ml-3 text-lg font-normal text-success-100">+51</span></span
                  >
                </div>
                <div class="flex items-center gap-6">
                  <span
                    class="cursor-pointer rounded-3xl bg-gray-90 px-3 py-2 text-lg dark-mode:bg-dark-mode-60 dark-mode:text-dark-mode-15"
                    >Cumulative</span
                  >
                  <span class="cursor-pointer text-lg">Daily</span>
                </div>
              </div>
            </div>
            <div
              class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
            >
              <div class="flex items-center justify-between">
                <div class="grid gap-1">
                  <span class="text-lg text-gray-80">Impressions</span>
                  <span class="flex items-center text-2xl font-bold">
                    35,178
                    <span class="ml-3 flex items-center text-lg font-normal text-danger-100">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.0465 9.61984L7.99979 13.6665L3.95312 9.61984"
                          stroke="#FF3C3C"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8 2.33322V13.5532"
                          stroke="#FF3C3C"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      17%
                    </span>
                  </span>
                </div>
                <div class="flex items-center gap-6">
                  <span
                    class="cursor-pointer rounded-3xl bg-gray-90 px-3 py-2 text-lg dark-mode:bg-dark-mode-60 dark-mode:text-dark-mode-15"
                    >Cumulative</span
                  >
                  <span class="cursor-pointer text-lg">Daily</span>
                </div>
              </div>
            </div>
            <div
              class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
            >
              <div class="flex items-center justify-between">
                <div class="grid gap-1">
                  <span class="text-lg text-gray-80">Engagement Rate</span>
                  <span class="flex items-center text-2xl font-bold">
                    2%
                    <span class="ml-3 flex items-center text-lg font-normal text-success-100">
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.38 6.38016L8.33329 2.3335L4.28662 6.38016"
                          stroke="#28AF87"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.3335 13.6668V2.44678"
                          stroke="#28AF87"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      19%
                    </span>
                  </span>
                </div>
                <div class="flex items-center gap-6">
                  <span
                    class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-gray-90 text-lg dark-mode:bg-dark-mode-60 dark-mode:text-dark-mode-15"
                    >#</span
                  >
                  <span class="cursor-pointer text-lg">%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        data-cy="analytics-profile"
        v-if="publicUserProfile"
        class="my-auto flex space-x-4 rounded-lg bg-block p-4"
      >
        <img
          class="my-auto flex h-12 w-12 items-center justify-center rounded-full"
          alt="Image placeholder"
          :src="user.photoURL"
        />
        <div>
          <a
            class="my-auto"
            :href="`https://twitter.com/${user.username}`"
            target="_blank"
            style="color: inherit"
          >
            <h3>{{ user.name }}</h3>
            <span>@{{ user.username }}</span>
          </a>
        </div>
      </div>
      <not-found v-if="userNotFound" />
      <div
        v-else-if="publicUserProfile && !publicUserProfile.isAnalyticsPagePublic"
        class="pt-5 pb-4 text-center"
        data-cy="analytics-private"
      >
        <p>This page is private.</p>
      </div>
      <div data-cy="analytics-public" v-else>
        <div class="flex items-center mb-6 justify-between">
          <h1>Analytics</h1>
          <div
            v-if="currentUser && !publicUserProfile && canUserViewAnalytics"
            class="flex flex-row items-center space-x-2"
          >
            <span class="text-base">Make this page public</span>
            <div class="toggler">
              <input
                data-cy="make-public-switch"
                v-model="isAnalyticsPagePublic"
                type="checkbox"
                name="enable-analytics-public"
                id="enable-analytics-public"
              />
              <label for="enable-analytics-public"></label>
            </div>
          </div>
        </div>
        <div class="mb-6 space-y-6" v-if="!canUserViewAnalytics">
          <base-alert
            data-cy="analytics-subscription-alert"
            type="warning"
            radius="rounded-2xl"
            v-if="isUserPlanBasicOrBelow(user.customerStatus, false)"
          >
            <p>
              You are currently on the
              {{ user.customerStatus === 'none' ? 'free' : user.customerStatus }}
              {{ user.customerStatus !== 'trial' ? 'plan' : '' }}. <br />
              <router-link data-cy="analytics-upgrade-link" to="/billing" class="text-main-color-100">
                Grab a Hypefury subscription
              </router-link>
              and enjoy in-depth Analytics and many other features!
            </p>
          </base-alert>

          <base-alert
            data-cy="analytics-subscription-alert"
            type="warning"
            radius="rounded-2xl"
            v-if="user.customerStatus === 'standard'"
          >
            <p>
              Hey! So far, we were providing in-depth Analytics to both our
              {{ getPlanLabel('premium') }} and {{ getPlanLabel('standard') }} users.<br />
              However, new restrictions (rate limits) imposed by Twitter prevent us from
              guaranteeing a flawless Analytics service to everyone and we had to make it part of
              the {{ getPlanLabel('premium') }} plan only.<br />
              Meanwhile, we are looking into ways to restore it in the future.<br /><br />
              <upgrade-to-premium-link>Click here</upgrade-to-premium-link>
              to upgrade to {{ getPlanLabel('premium') }} and get Analytics access back.
            </p>
          </base-alert>

          <base-alert data-cy="analytics-sample-alert" type="info" radius="rounded-2xl">
            This is a sample of what your Analytics page could look like once you upgrade.
          </base-alert>
        </div>
        <div class="space-y-6">
          <div class="loader" v-if="isLoadingFirstTime">
            <span class="loading loading-lg"
              ><inline-svg src="/img/icons/loading.svg"></inline-svg
            ></span>
          </div>
          <div class="relative" v-else>
            <div :class="{ 'sample-overlay': !canUserViewAnalytics }"></div>
            <div class="space-y-6">
              <div class="flex items-center justify-between">
                <div class="relative z-10 flex space-x-2">
                  <base-button
                    class="button small-button"
                    :class="{
                      'primary-button': analyticsToDisplay === 'tweets',
                      'secondary-button': analyticsToDisplay !== 'tweets',
                    }"
                    @click="toggleAnalyticsToDisplay('tweets')"
                  >
                    Tweets
                  </base-button>
                  <base-button
                    class="button small-button"
                    :class="{
                      'primary-button': analyticsToDisplay === 'followers',
                      'secondary-button': analyticsToDisplay !== 'followers',
                    }"
                    @click="toggleAnalyticsToDisplay('followers')"
                  >
                    Followers
                  </base-button>
                </div>
                <div class="flex space-x-2">
                  <div v-if="!publicUserProfile">
                    <base-dropdown
                      menu-fixed
                      class="nav-item hidden sm:block"
                      tag="div"
                      title-tag="button"
                      title-classes="nav-link pr-0"
                      :disabled="isUpdatingDaysSpan"
                    >
                      <base-button
                        class="border-toggle flex cursor-pointer items-center gap-3 rounded-lg bg-canvas px-4 py-3"
                        @click.prevent=""
                        slot="title-container"
                        :disabled="isUpdatingDaysSpan"
                      >
                        <span class="font-main-font">Export as CSV</span>
                        <inline-svg class="fill-current h-4 w-4 inline -mr-1 ml-3"  src="/img/icons/arrow-down.svg"></inline-svg>
                      </base-button>
                      <base-button
                        class="new-dropdown-item dark-mode-dropdown-item"
                        @click="exportUserAnalytics('tweets-daily-analytics')"
                      >
                        <span>Tweets Analytics</span>
                      </base-button>
                      <base-button
                        class="new-dropdown-item dark-mode-dropdown-item"
                        @click="exportUserAnalytics('tweets-statistics')"
                      >
                        <span>Tweets Statistics</span>
                      </base-button>
                      <base-button
                        class="new-dropdown-item dark-mode-dropdown-item"
                        @click="exportUserAnalytics('followers-daily-analytics')"
                      >
                        <span>Followers Analytics</span>
                      </base-button>
                    </base-dropdown>
                  </div>

                  <base-dropdown
                    menu-fixed
                    class="nav-item"
                    tag="div"
                    title-tag="button"
                    title-classes="nav-link pr-0"
                  >
                    <base-button
                      data-cy="filter-button"
                      class="border-toggle flex cursor-pointer items-center gap-3 rounded-lg bg-canvas px-4 py-3"
                      @click.prevent=""
                      slot="title-container"
                    >
                      <span v-if="daysSpan === 7" class="font-main-font"
                        ><span class="hidden sm:inline">Data for last</span> 7 days</span
                      >
                      <span v-else-if="daysSpan === 14" class="font-main-font"
                        ><span class="hidden sm:inline">Data for last</span> 14 days</span
                      >
                      <span v-else-if="daysSpan === 30" class="font-main-font"
                        ><span class="hidden sm:inline">Data for last</span> 30 days</span
                      >
                      <span v-else-if="daysSpan === 60" class="font-main-font"
                        ><span class="hidden sm:inline">Data for last</span> 60 days</span
                      >
                      <span v-else-if="daysSpan === 90" class="font-main-font"
                        ><span class="hidden sm:inline">Data for last</span> 90 days</span
                      >
                      <span v-else-if="daysSpan === 365" class="font-main-font"
                        ><span class="hidden sm:inline">Data for last</span> 1 year</span
                      >
                      <inline-svg class="fill-current h-4 w-4 inline -mr-1 ml-3" src="/img/icons/calendar.svg" />
                    </base-button>
                    <template>
                      <base-button
                        class="new-dropdown-item dark-mode-dropdown-item"
                        :class="{
                          active: daysSpan === 7,
                        }"
                        @click="updateDaysSpan(7)"
                      >
                        <inline-svg src="/img/icons/calendar.svg" />
                        <span>7 days</span>
                      </base-button>
                      <base-button
                        class="new-dropdown-item dark-mode-dropdown-item"
                        :class="{
                          active: daysSpan === 14,
                        }"
                        @click="updateDaysSpan(14)"
                      >
                        <inline-svg src="/img/icons/calendar.svg" />
                        <span>14 days</span>
                      </base-button>
                      <base-button
                        class="new-dropdown-item dark-mode-dropdown-item"
                        :class="{
                          active: daysSpan === 30,
                        }"
                        @click="updateDaysSpan(30)"
                      >
                        <inline-svg src="/img/icons/calendar.svg" />
                        <span>30 days</span>
                      </base-button>
                      <base-button
                        class="new-dropdown-item dark-mode-dropdown-item"
                        :class="{
                          active: daysSpan === 60,
                        }"
                        @click="updateDaysSpan(60)"
                      >
                        <inline-svg src="/img/icons/calendar.svg" />
                        <span>60 days</span>
                      </base-button>
                      <base-button
                        class="new-dropdown-item dark-mode-dropdown-item"
                        :class="{
                          active: daysSpan === 90,
                        }"
                        @click="updateDaysSpan(90)"
                      >
                        <inline-svg src="/img/icons/calendar.svg" />
                        <inline-svg
                          v-if="isUserPlanStandardOrBelow(user.customerStatus)"
                          src="/img/icons/premium.svg"
                        />
                        <span>90 days</span>
                      </base-button>
                      <base-button
                        class="new-dropdown-item dark-mode-dropdown-item"
                        :class="{
                          active: daysSpan === 365,
                        }"
                        @click="updateDaysSpan(365)"
                      >
                        <inline-svg src="/img/icons/calendar.svg" />
                        <inline-svg
                          v-if="isUserPlanStandardOrBelow(user.customerStatus)"
                          src="/img/icons/premium.svg"
                        />
                        <span>1 year</span>
                      </base-button>
                    </template>
                  </base-dropdown>
                </div>
              </div>
              <div class="text-muted" v-if="analyticsToDisplay === 'tweets'">
                {{ tweetAnalyticsLastUpdatedAtText }}
              </div>
            </div>
            <div class="relative mt-6">
              <div class="loader sticky pb-8" v-if="isUpdatingDaysSpan">
                <span class="loading loading-lg"
                  ><inline-svg src="/img/icons/loading.svg"></inline-svg
                ></span>
              </div>
              <div
                class="space-y-6"
                data-cy="tweets-block"
                v-else-if="analyticsToDisplay === 'tweets'"
              >
                <div class="space-y-6" data-cy="engagements-block">
                  <div
                    class="space-y-4 sm:flex sm:items-baseline sm:justify-between sm:space-y-0"
                  >
                    <h3>
                      <div class="flex items-center space-x-2">
                        <span class="text-lg font-main-font">Engagements per Tweet</span>
                        <info-tooltip
                          v-if="
                            isSomeTweetsDataMissing ||
                            isSomeEngagementsDataWrong ||
                            isSomeTweetsDataWrong
                          "
                          content="Some data might be missing"
                          type="error"
                        ></info-tooltip>
                      </div>
                    </h3>
                  </div>
                  <div class="grid grid-cols-2 grid-rows-2 gap-6 md:grid-cols-4 md:grid-rows-1">
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Today</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(todaysData.engagementsPerTweet) }}
                      </div>
                    </div>
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">14 day avg</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(fourteenDaysAnalytics.engagementsPerTweetAverage) }}
                      </div>
                    </div>
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Highest</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(engagementsPerTweetMeta.highest) }}
                      </div>
                    </div>
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Average</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(engagementsPerTweetMeta.average) }}
                      </div>
                    </div>
                  </div>
                  <div class="-ml-4 -mr-4">
                    <chart-container
                      chart-type="line"
                      :chart-data="engagementsPerTweetChartData"
                    />
                  </div>
                </div>
                <div class="space-y-6">
                  <h3>
                    <div class="flex items-center space-x-2">
                      <span class="text-lg font-main-font">Engagement rate</span>
                      <info-tooltip
                        v-if="
                          isSomeTweetsDataMissing ||
                          isSomeEngagementsDataWrong ||
                          isSomeImpressionsDataWrong
                        "
                        content="Some data might be missing"
                        type="error"
                      ></info-tooltip>
                    </div>
                  </h3>
                  <div class="grid grid-cols-2 gap-6">
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Highest</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(engagementsPerImpressionMeta.highest) }}
                      </div>
                    </div>
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Average</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(engagementsPerImpressionMeta.average) }}
                      </div>
                    </div>
                  </div>
                  <div class="-ml-4 -mr-4">
                    <chart-container
                      chart-type="line"
                      :chart-data="engagementsPerImpressionChartData"
                    />
                  </div>
                </div>
                <div class="space-y-6" data-cy="impressions-block">
                  <h3>
                    <div class="flex items-center space-x-2">
                      <span class="text-lg font-main-font">Impressions</span>
                      <info-tooltip
                        v-if="isSomeTweetsDataMissing || isSomeImpressionsDataWrong"
                        content="Some data might be missing"
                        type="error"
                      ></info-tooltip>
                    </div>
                  </h3>
                  <div class="grid grid-cols-2 gap-6 md:grid-cols-3">
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Today</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(todaysData.impressions) }}
                      </div>
                    </div>
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">14 day avg</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(fourteenDaysAnalytics.impressionsAverage) }}
                      </div>
                    </div>
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Last {{ daysSpan }} days</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(impressionsMeta.inSelectedDaysSpan) }}
                      </div>
                    </div>
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Highest</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(impressionsMeta.highest) }}
                      </div>
                    </div>
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Average</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(impressionsMeta.average) }}
                      </div>
                    </div>
                  </div>
                  <div class="-ml-4 -mr-4">
                    <chart-container chart-type="bar" :chart-data="impressionsChartData" />
                  </div>
                </div>
                <div class="space-y-6">
                  <h3>
                    <div class="flex items-center space-x-2">
                      <span class="text-lg font-main-font">Tweets</span>
                      <info-tooltip
                        v-if="isSomeTweetsDataMissing || isSomeTweetsDataWrong"
                        content="Some data might be missing"
                        type="error"
                      ></info-tooltip>
                    </div>
                  </h3>
                  <div class="grid grid-cols-2 gap-6 md:grid-cols-3">
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Today</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(todaysData.tweets) }}
                      </div>
                    </div>
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">14 day avg</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(fourteenDaysAnalytics.tweetsAverage) }}
                      </div>
                    </div>
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Last {{ daysSpan }} days</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(tweetsMeta.inSelectedDaysSpan) }}
                      </div>
                    </div>
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Highest</div>
                     <div class="text-2xl font-bold">
                        {{ formatNumber(tweetsMeta.highest) }}
                      </div>
                    </div>
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Average</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(tweetsMeta.average) }}
                      </div>
                    </div>
                  </div>
                  <div class="-ml-4 -mr-4">
                    <chart-container chart-type="bar" :chart-data="tweetsChartData" />
                  </div>
                </div>
                <div class="space-y-6">
                  <div
                    class="space-y-4 sm:flex sm:items-baseline sm:justify-between sm:space-y-0"
                  >
                    <h3 class="text-lg font-main-font">Tweets</h3>
                  </div>
                  <div class="table-container without-scrollbar">
                    <table class="w-full">
                      <tr>
                        <th
                          class="cursor-pointer text-left"
                          @click="orderTweetsBy('time')"
                          :class="getTweetsTableHeaderClass('time')"
                        >
                          Date
                        </th>
                        <th class="bg-white dark-mode-bg text-left">Tweet</th>
                        <th
                          class="cursor-pointer text-center"
                          @click="orderTweetsBy('impressions')"
                          :class="getTweetsTableHeaderClass('impressions')"
                        >
                          Impressions
                        </th>
                        <th
                          class="cursor-pointer text-center"
                          @click="orderTweetsBy('engagements')"
                          :class="getTweetsTableHeaderClass('engagements')"
                        >
                          Engagements
                        </th>
                        <th
                          class="cursor-pointer text-center"
                          @click="orderTweetsBy('engagementRate')"
                          :class="getTweetsTableHeaderClass('engagementRate')"
                        >
                          Eng. Rate
                        </th>
                        <th
                          class="cursor-pointer text-center"
                          @click="orderTweetsBy('favoriteCount')"
                          :class="getTweetsTableHeaderClass('favoriteCount')"
                        >
                          Likes
                        </th>
                        <th
                          class="cursor-pointer text-center"
                          @click="orderTweetsBy('replyCount')"
                          :class="getTweetsTableHeaderClass('replyCount')"
                        >
                          Replies
                        </th>
                        <th
                          class="cursor-pointer text-center"
                          @click="orderTweetsBy('retweetCount')"
                          :class="getTweetsTableHeaderClass('retweetCount')"
                        >
                          Retweets / Quotes
                        </th>
                        <th
                          class="cursor-pointer text-center"
                          @click="orderTweetsBy('bookmarkCount')"
                          :class="getTweetsTableHeaderClass('bookmarkCount')"
                        >
                          Bookmarks
                        </th>
                        <th
                          class="cursor-pointer text-center"
                          @click="orderTweetsBy('urlLinkClicks')"
                          :class="getTweetsTableHeaderClass('urlLinkClicks')"
                        >
                          Link Clicks
                        </th>
                        <th
                          class="cursor-pointer text-center"
                          @click="orderTweetsBy('userProfileClicks')"
                          :class="getTweetsTableHeaderClass('userProfileClicks')"
                        >
                          Profile Clicks
                        </th>
                            <th
                              class="cursor-pointer text-center"
                              :class="getTweetsTableHeaderClass('reuse')"
                            >
                              Re-use
                            </th>
                      </tr>
                      <tr v-for="tweet in tweets" v-bind:key="tweet.tweetId">
                        <td class="whitespace-no-wrap">
                          <a
                            target="_blank"
                            :href="`https://twitter.com/${user.username}/status/${tweet.tweetId}`"
                            class="text-main-color-100"
                          >
                            {{ formatDate(tweet.time) }}
                          </a>
                        </td>
                        <td :title="tweet.status" class="opacity-50" dir="auto">
                          <div class="truncate" style="max-width: 150px">
                            {{ tweet.status }}
                          </div>
                        </td>
                        <td class="text-center opacity-50">
                          {{ formatNumber(tweet.impressions) }}
                        </td>
                        <td class="text-center opacity-50">
                          {{ formatNumber(tweet.engagements) }}
                        </td>
                        <td class="text-center opacity-50">
                          {{ formatNumber(tweet.engagementRate) }}%
                        </td>
                        <td class="text-center opacity-50">
                          {{ formatNumber(tweet.favoriteCount) }}
                        </td>
                        <td class="text-center opacity-50">
                          {{ formatNumber(tweet.replyCount) }}
                        </td>
                        <td class="text-center opacity-50">
                          {{ formatNumber(tweet.retweetCount) }} /
                          {{ formatNumber(tweet.quoteCount) }}
                        </td>
                        <td class="text-center opacity-50">
                          {{ formatNumber(tweet.bookmarkCount) }}
                        </td>
                        <td class="text-center opacity-50">
                          {{ formatNumber(tweet.urlLinkClicks) }}
                        </td>
                        <td class="text-center opacity-50">
                          {{ formatNumber(tweet.userProfileClicks) }}
                        </td>
                            <td class="text-center opacity-50">
                              <new-button
                                @click="reuseTweet(tweet)"
                                v-if="tweet.source === 'hypefury-app'"
                                type="light"
                                icon
                              >
                                <inline-svg class="h-6 w-6" src="/img/icons/new-retweet.svg" />
                              </new-button>
                            </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="space-y-6" v-else-if="analyticsToDisplay === 'followers'">
                <div class="space-y-6">
                  <h3>
                    <div class="flex items-center space-x-2">
                      <span class="text-lg">Followers</span>
                      <info-tooltip
                        v-if="isSomeFollowersDataMissing"
                        content="Some data might be missing"
                        type="error"
                      ></info-tooltip>
                    </div>
                  </h3>
                  <div class="grid grid-cols-2 gap-6">
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Total</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(followersMeta.total) }}
                      </div>
                    </div>
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Last 14 days</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(fourteenDaysAnalytics.followersGain, true) }}
                        ({{ formatNumber(fourteenDaysAnalytics.followersGainRate, true) }}%)
                      </div>
                    </div>
                  </div>
                  <div class="-ml-4 -mr-4">
                    <chart-container
                      chart-type="line"
                      :chart-data="followersTotalGrowthChartData"
                      :beginAtZero="false"
                    />
                  </div>
                </div>
                <div class="space-y-6">
                  <h3>
                    <div class="flex items-center space-x-2">
                      <span class="text-lg">Followers Daily Growth</span>
                      <info-tooltip
                        v-if="isSomeFollowersDataMissing"
                        content="Some data might be missing"
                        type="error"
                      ></info-tooltip>
                    </div>
                  </h3>
                  <div class="grid grid-cols-2 gap-6 sm:grid-cols-3">
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Last {{ daysSpan }} days</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(followersMeta.inSelectedDaysSpan, true) }}
                      </div>
                    </div>
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Best</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(followersMeta.best, true) }}
                      </div>
                    </div>
                    <div
                      class="analytics-data-cards border rounded-2xl border-gray-90 bg-white p-6 shadow-xs"
                    >
                      <div class="text-lg text-gray-80">Average</div>
                      <div class="text-2xl font-bold">
                        {{ formatNumber(followersMeta.average, true) }}
                      </div>
                    </div>
                  </div>
                  <div class="-ml-4 -mr-4">
                    <chart-container
                      chart-type="bar"
                      :chart-data="followersDailyDifferenceChartData"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <upgrade-to-premium-pop-up
            :show="showUpgradeToPremiumPopUp"
            @continue-operation="updateDaysSpan(daysSpanAfterUpgrading)"
            @close="showUpgradeToPremiumPopUp = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const fb = require('@/firebase');
  const lodash = require('lodash');
  const moment = require('moment-timezone');
  const Papa = require('papaparse');
  import ChartContainer from '@/components/Charts/ChartContainer';
  import controller from '@/controller';
  import dao from '@/dao';
  import { mapGetters } from 'vuex';
  import NotFound from '@/views/GeneralViews/NotFoundPage.vue';
  import { PublicUserProfile } from '@/models/PublicUserProfile';
  import saveAs from 'file-saver';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { EventBus } from '@/event-bus';
  import CustomerStatusMixin from './Mixins/CustomerStatusMixin.vue';

  /*
   * Total number of times a user interacted with a Tweet. Clicks anywhere on the Tweet,
   * including Retweets, replies, follows, likes, links, cards, hashtags, embedded media, username,
   * profile photo, or Tweet expansion
   */

  export default {
    name: 'analytics',
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      user() {
        return this.publicUserProfile || this.userProfile;
      },
      canUserViewAnalytics() {
        if (!this.user || !this.user.customerStatus) return true;
        const isTrialAllowed = false;
        const canUserViewAnalytics = this.isUserPlanStandardOrAbove(
          this.user.customerStatus,
          isTrialAllowed
        );
        return canUserViewAnalytics;
      },
    },
    components: {
      ChartContainer,
      NotFound,
    },
    async created() {
      this.isLoadingFirstTime = true;
      if (!this.$route.params.username) {
        this.userNotFound = true;
        return;
      }
      if (!this.userProfile || this.$route.params.username !== this.userProfile.username) {
        const users = await fb.publicUsersCollection
          .where('username', '==', this.$route.params.username)
          .get();
        const doc = users.docs[0];

        if (!doc) {
          this.userNotFound = true;
          return;
        } else {
          this.publicUserProfile = new PublicUserProfile(doc.data());
          if (!this.publicUserProfile.isAnalyticsPagePublic) return;
        }
      }
      this.isAnalyticsPagePublic = this.userProfile && this.userProfile.isAnalyticsPagePublic;
      if (this.canUserViewAnalytics) {
        await this.getFourteenDaysAnalytics().catch((error) => {
          this.handleError(error);
        });
        this.isLoadingFirstTime = false;
        await this.updateDaysSpan(this.daysSpan);
      } else {
        this.$nextTick(function () {
          this.populateSampleData();
          this.isLoadingFirstTime = false;
        });
      }
    },
    data() {
      return {
        analyticsToDisplay: 'tweets',
        isAnalyticsPagePublic: null,
        publicUserProfile: null,
        userNotFound: false,
        daysSpan: 7,
        daysSpanAfterUpgrading: 7,
        engagementsPerImpressionChartData: {},
        engagementsPerTweetChartData: {},
        impressionsChartData: {},
        tweetsChartData: {},
        followersTotalGrowthChartData: {},
        followersDailyDifferenceChartData: {},
        tweets: [],
        tweetsOrderParam: 'time',
        tweetsSortOrder: 'desc',
        tweetsAnalytics: {},
        followersAnalytics: {},
        tweetAnalyticsLastUpdatedAtText: '',
        engagementsPerImpressionMeta: {
          highest: 0,
          average: 0,
        },
        engagementsPerTweetMeta: {
          highest: 0,
          average: 0,
        },
        impressionsMeta: {
          inSelectedDaysSpan: 0,
          highest: 0,
          average: 0,
        },
        tweetsMeta: {
          inSelectedDaysSpan: 0,
          highest: 0,
          average: 0,
        },
        followersMeta: {
          total: 0,
          inSelectedDaysSpan: 0,
          best: 0,
          average: 0,
        },
        todaysData: {
          engagementsPerTweet: 0,
          impressions: 0,
          tweets: 0,
        },
        fourteenDaysAnalytics: {
          followersGain: 0,
          followersGainRate: 0,
          engagementsPerTweetAverage: 0,
          impressionsAverage: 0,
          tweetsAverage: 0,
        },
        isLoadingFirstTime: false,
        isUpdatingDaysSpan: false,
        showUpgradeToPremiumPopUp: false,
        isSomeTweetsDataMissing: false,
        isSomeFollowersDataMissing: false,
        isSomeEngagementsDataWrong: false,
        isSomeImpressionsDataWrong: false,
        isSomeTweetsDataWrong: false,
        showAdBlockerMessage: false,
      };
    },
    methods: {
      toggleAnalyticsToDisplay(analyticsToDisplay) {
        this.analyticsToDisplay = analyticsToDisplay;
        this.$eventStore.analytics(lodash.capitalize(analyticsToDisplay));
      },
      async exportUserAnalytics(option) {
        let rows = [];
        let columns = [];
        let isDaysSpanUpdated = true;
        switch (option) {
          case 'tweets-daily-analytics':
            isDaysSpanUpdated = this.tweetsAnalytics.dailyDifference.length !== this.daysSpan;
            if (isDaysSpanUpdated) {
              this.tweetsAnalytics = await controller.analytics.getTweetsAnalytics(
                this.user.uid,
                this.daysSpan
              );
            }
            rows = this.tweetsAnalytics.dailyDifference.map((data) => {
              const engagements = this.getEngagements(data);
              return [
                moment.tz(data.date, this.userProfile.timezone).format('MM-DD-Y'),
                data.tweetsCount === 0 ? 0 : engagements / data.tweetsCount,
                data.impressions === 0 ? 0 : (engagements / data.impressions) * 100,
                data.impressions,
                data.tweetsCount,
              ];
            });
            columns = ['Date', 'Engagements per Tweet', 'Engagement rate', 'Impressions', 'Tweets'];
            break;
          case 'tweets-statistics':
            isDaysSpanUpdated = this.tweetsAnalytics.dailyDifference.length !== this.daysSpan;
            if (isDaysSpanUpdated) {
              this.tweetsAnalytics = await controller.analytics.getTweetsAnalytics(
                this.user.uid,
                this.daysSpan
              );
            }
            rows = this.tweets.map((data) => {
              return [
                moment.tz(data.time, this.userProfile.timezone).format('MM-DD-Y'),
                data.status,
                data.impressions,
                data.engagements,
                data.engagementRate,
                data.favoriteCount,
                data.replyCount,
                `${data.retweetCount} / ${data.quoteCount}`,
                data.bookmarkCount,
                lodash.get(data, 'urlLinkClicks', 0),
                data.userProfileClicks,
              ];
            });
            columns = [
              'Date',
              'Tweets',
              'Impressions',
              'Engagements',
              'Engagement rate',
              'Likes',
              'Replies',
              'Retweets /Quotes',
              'Bookmarks',
              'Link Clicks',
              'Profile Clicks',
            ];
            break;
          case 'followers-daily-analytics':
            isDaysSpanUpdated =
              this.followersAnalytics.dates &&
              this.followersAnalytics.dates.length !== this.daysSpan;
            if (!this.followersAnalytics.dates || isDaysSpanUpdated) {
              this.followersAnalytics = await controller.analytics.getFollowersAnalytics(
                this.user.uid,
                this.daysSpan
              );
            }
            rows = this.followersAnalytics.dates.map((date, i) => {
              return [
                moment.tz(date, this.userProfile.timezone).format('MM-DD-Y'),
                this.followersAnalytics.dailyDifference[i],
                this.followersAnalytics.totalGrowth[i],
              ];
            });
            columns = ['Date', 'Difference', 'Total'];
            break;
          default:
            break;
        }

        const csvContent = Papa.unparse({ data: rows, fields: columns });
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, `${option}-${this.daysSpan}-days.csv`);
      },
      formatNumber(n, displaySign) {
        if (n)
          return displaySign && n > 0 ? '+' + n.toLocaleString('en-US') : n.toLocaleString('en-US');
        else if (n === 0) return 0;
      },
      formatDate(date) {
        const timezone =
          this.userProfile && this.userProfile.timezone
            ? this.userProfile.timezone
            : moment.tz.guess();
        return moment.tz(date, timezone).format('MMM D Y');
      },
      async getFourteenDaysAnalytics() {
        const tweetsAnalytics = await controller.analytics.getTweetsAnalytics(this.user.uid, 14);
        const followersAnalytics = await controller.analytics.getFollowersAnalytics(
          this.user.uid,
          14
        );
        if (!tweetsAnalytics || !followersAnalytics) throw 'No analytics data';

        // For the day 1 of user, the daily difference is not returned.
        if (!tweetsAnalytics.dailyDifference) return;

        const engagements = tweetsAnalytics.dailyDifference.map((data) =>
          this.getEngagements(data)
        );
        const impressions = tweetsAnalytics.dailyDifference.map((data) => data.impressions);
        const tweets = tweetsAnalytics.dailyDifference.map((data) => data.tweetsCount);
        const engagementsPerTweet = engagements.map((e, i) =>
          tweets[i] === 0 ? 0 : e / tweets[i]
        );
        const followers = followersAnalytics.totalGrowth;
        const totalFollowers = followers[followers.length - 1];

        this.fourteenDaysAnalytics.followersGain = followers[followers.length - 1] - followers[0];
        this.fourteenDaysAnalytics.followersGainRate =
          (this.fourteenDaysAnalytics.followersGain /
            (totalFollowers - this.fourteenDaysAnalytics.followersGain)) *
          100;
        this.fourteenDaysAnalytics.engagementsPerTweetAverage = lodash.meanBy(
          engagementsPerTweet,
          (p) => p
        );
        this.fourteenDaysAnalytics.impressionsAverage = Math.round(
          lodash.meanBy(impressions, (p) => p)
        );
        this.fourteenDaysAnalytics.tweetsAverage = Math.round(lodash.meanBy(tweets, (p) => p));
        return Promise.resolve();
      },
      updateTweetAnalyticsLastUpdatedAtText() {
        if (this.user.tweetMetricsUpdatedAt) {
          this.tweetAnalyticsLastUpdatedAtText =
            'Updated ' + moment(this.user.tweetMetricsUpdatedAt.toDate()).fromNow();
        }
      },
      getTweetsAnalyticsData(analytics) {
        if (!analytics) throw 'No analytics data';
        // For the day 1 of user, the daily difference is not returned.
        if (!analytics.dailyDifference) {
          const engagements = [this.getEngagements(analytics.totalValues)];
          const impressions = [analytics.totalValues.impressions];
          const tweets = [analytics.totalValues.tweetsCount];
          const engagementsPerTweet = [
            this.getEngagements(analytics.totalValues) / analytics.totalValues.tweetsCount,
          ];
          const engagementsPerImpression = [
            (this.getEngagements(analytics.totalValues) / analytics.totalValues.impressions) * 100,
          ];
          const dates = [moment(analytics.totalValues.date)];

          this.todaysData.engagementsPerTweet = engagementsPerTweet[0];
          this.todaysData.impressions = impressions[0];
          this.todaysData.tweets = tweets[0];
          return {
            engagementsPerTweet,
            engagementsPerImpression,
            engagements,
            impressions,
            tweets,
            dates,
          };
        }

        const engagements = analytics.dailyDifference.map((data) => {
          const engagements = this.getEngagements(data);
          if (engagements < 0) {
            this.isSomeEngagementsDataWrong = true;
            return 0;
          }
          return engagements;
        });
        const impressions = analytics.dailyDifference.map((data) => {
          if (data.impressions < 0) {
            this.isSomeImpressionsDataWrong = true;
            return 0;
          }
          return data.impressions;
        });
        const tweets = analytics.dailyDifference.map((data) => {
          if (data.tweetsCount < 0) {
            this.isSomeTweetsDataWrong = true;
            return 0;
          }
          return data.tweetsCount;
        });

        const engagementsPerTweet = engagements.map((e, i) =>
          tweets[i] === 0 ? 0 : e / tweets[i]
        );
        const engagementsPerImpression = engagements.map((e, i) =>
          impressions[i] === 0 ? 0 : (e / impressions[i]) * 100
        );

        this.todaysData.engagementsPerTweet = engagementsPerTweet[engagementsPerTweet.length - 1];
        this.todaysData.impressions = impressions[impressions.length - 1];
        this.todaysData.tweets = tweets[tweets.length - 1];

        this.engagementsPerImpressionMeta.highest = Math.max(...engagementsPerImpression);
        this.engagementsPerImpressionMeta.average = lodash.meanBy(
          engagementsPerImpression,
          (p) => p
        );
        this.engagementsPerTweetMeta.highest = Math.max(...engagementsPerTweet);
        this.engagementsPerTweetMeta.average = lodash.meanBy(engagementsPerTweet, (p) => p);
        this.impressionsMeta.inSelectedDaysSpan = analytics.totalValues.impressions;
        this.impressionsMeta.highest = Math.max(...impressions);
        this.impressionsMeta.average = Math.round(lodash.meanBy(impressions, (p) => p));
        this.tweetsMeta.inSelectedDaysSpan = analytics.totalValues.tweetsCount;
        this.tweetsMeta.highest = Math.max(...tweets);
        this.tweetsMeta.average = Math.round(lodash.meanBy(tweets, (p) => p));

        this.isSomeTweetsDataMissing = analytics.dailyDifference.some(
          (data) => data.isSomeDataMissing
        );

        const dates = analytics.dailyDifference.map((data) => moment(data.date));
        return {
          engagementsPerTweet,
          engagementsPerImpression,
          engagements,
          impressions,
          tweets,
          dates,
        };
      },
      getEngagements(tweet) {
        const metrics = [
          tweet.favoriteCount,
          tweet.replyCount,
          tweet.retweetCount,
          tweet.quoteCount,
          tweet.bookmarkCount,
          tweet.userProfileClicks,
          tweet.urlLinkClicks,
        ];
        return lodash.sum(metrics);
      },
      getFollowersAnalyticsData(followersAnalytics) {
        if (!followersAnalytics) throw 'No analytics data';
        // For the day 1 of user, the daily difference is not returned.
        if (!followersAnalytics.dailyDifference) {
          const dates = followersAnalytics.dates.map((date) => moment(date));
          const dailyDifference = [...followersAnalytics.totalGrowth];
          const totalGrowth = followersAnalytics.totalGrowth;
          this.followersMeta.total = followersAnalytics.totalGrowth[0];
          return {
            dates,
            dailyDifference,
            totalGrowth,
          };
        }
        const dates = followersAnalytics.dates
          .filter((d, i) => !followersAnalytics.isSomeDataMissing[i])
          .map((date) => moment(date));
        const dailyDifference = followersAnalytics.dailyDifference.filter(
          (d, i) => !followersAnalytics.isSomeDataMissing[i]
        );
        const totalGrowth = followersAnalytics.totalGrowth.filter(
          (d, i) => !followersAnalytics.isSomeDataMissing[i]
        );

        this.followersMeta.total = totalGrowth[totalGrowth.length - 1];
        this.followersMeta.inSelectedDaysSpan = lodash.sum(dailyDifference);
        this.followersMeta.best = Math.max(...dailyDifference);
        this.followersMeta.average = Math.round(lodash.meanBy(dailyDifference, (p) => p));

        this.isSomeFollowersDataMissing = followersAnalytics.isSomeDataMissing.some((e) => e);

        return {
          dates,
          dailyDifference,
          totalGrowth,
        };
      },
      handleError(error) {
        this.isLoadingFirstTime = false;
        if (error === 'No analytics data') {
          this.tweetAnalyticsLastUpdatedAtText = 'Not updated yet';
          this.swalModal({
            title: 'No analytics yet',
            text: `The analytics will be updated soon!`,
            type: 'info',
          });
        } else {
          console.error(error);
          this.swalModal({
            title: 'Error',
            text: `An error occured while fetching the analytics. Please make sure your adblocker is disabled.`,
            type: 'error',
          });
        }
      },
      orderTweetsBy(param) {
        this.tweetsSortOrder =
          this.tweetsOrderParam !== param
            ? 'desc'
            : this.tweetsSortOrder === 'asc'
            ? 'desc'
            : 'asc';
        this.tweetsOrderParam = param;
        this.tweets = lodash.orderBy(this.tweets, [this.tweetsOrderParam], [this.tweetsSortOrder]);
      },
      populateTweetsAnalyticsCharts(analytics) {
        const {
          engagementsPerTweet,
          engagementsPerImpression,
          engagements,
          impressions,
          tweets,
          dates,
        } = analytics;

        // When we have data for days less than the daysSpan, we need to fill the arrays with 0's,
        // to cover the daysSpan length
        while (tweets.length < this.daysSpan) {
          engagementsPerTweet.unshift(0);
          engagementsPerImpression.unshift(0);
          engagements.unshift(0);
          impressions.unshift(0);
          tweets.unshift(0);
          const firstDate = dates[0].clone();
          dates.unshift(firstDate.subtract(1, 'days'));
        }

        const datesForLabels = dates.map((date) => date.format('MMM D'));

        this.engagementsPerTweetChartData = {
          labels: datesForLabels,
          datasets: [
            {
              label: 'Engagements per tweet',
              data: engagementsPerTweet,
            },
          ],
        };
        this.engagementsPerImpressionChartData = {
          labels: datesForLabels,
          datasets: [
            {
              label: 'Engagement rate',
              data: engagementsPerImpression,
            },
          ],
        };
        this.impressionsChartData = {
          labels: datesForLabels,
          datasets: [
            {
              label: 'Impressions',
              data: impressions,
            },
          ],
        };
        this.tweetsChartData = {
          labels: datesForLabels,
          datasets: [
            {
              label: 'Tweets',
              data: tweets,
            },
          ],
        };
      },
      populateFollowersAnalyticsCharts(analytics) {
        const { dates, dailyDifference, totalGrowth } = analytics;

        const datesForLabels = dates.map((date) => date.format('MMM D'));

        this.followersDailyDifferenceChartData = {
          labels: datesForLabels,
          datasets: [
            {
              label: 'Followers Daily',
              data: dailyDifference,
            },
          ],
        };
        this.followersTotalGrowthChartData = {
          labels: datesForLabels,
          datasets: [
            {
              label: 'Followers',
              data: totalGrowth,
              backgroundColor: 'rgb(6, 123, 238, 0.6)',
              borderWidth: 0,
            },
          ],
        };
      },
      populateSampleData() {
        this.fourteenDaysAnalytics.followersGain = 256;
        this.fourteenDaysAnalytics.followersGainRate = 60.37;
        this.fourteenDaysAnalytics.engagementsPerTweetAverage = 31.57;
        this.fourteenDaysAnalytics.impressionsAverage = 1161;
        this.fourteenDaysAnalytics.tweetsAverage = 12;

        const engagements = [32, 256, 160, 256, 512, 512, 1024];
        const impressions = [128, 512, 640, 512, 2048, 1024, 4096];
        const tweets = [8, 16, 40, 24, 32, 32, 16];

        const engagementsPerTweet = engagements.map((e, i) =>
          tweets[i] === 0 ? 0 : e / tweets[i]
        );
        const engagementsPerImpression = engagements.map((e, i) =>
          impressions[i] === 0 ? 0 : (e / impressions[i]) * 100
        );
        const tweetsDates = [
          moment().subtract(6, 'days'),
          moment().subtract(5, 'days'),
          moment().subtract(4, 'days'),
          moment().subtract(3, 'days'),
          moment().subtract(2, 'days'),
          moment().subtract(1, 'days'),
          moment(),
        ];

        this.todaysData.engagementsPerTweet = engagementsPerTweet[engagementsPerTweet.length - 1];
        this.todaysData.impressions = impressions[impressions.length - 1];
        this.todaysData.tweets = tweets[tweets.length - 1];

        this.engagementsPerImpressionMeta.highest = Math.max(...engagementsPerImpression);
        this.engagementsPerImpressionMeta.average = lodash.meanBy(
          engagementsPerImpression,
          (p) => p
        );
        this.engagementsPerTweetMeta.highest = Math.max(...engagementsPerTweet);
        this.engagementsPerTweetMeta.average = lodash.meanBy(engagementsPerTweet, (p) => p);
        this.impressionsMeta.total = lodash.sum(impressions);
        this.impressionsMeta.highest = Math.max(...impressions);
        this.impressionsMeta.average = Math.round(lodash.meanBy(impressions, (p) => p));
        this.tweetsMeta.total = lodash.sum(tweets);
        this.tweetsMeta.highest = Math.max(...tweets);
        this.tweetsMeta.average = Math.round(lodash.meanBy(tweets, (p) => p));

        const followersDates = [
          moment().subtract(6, 'days'),
          moment().subtract(5, 'days'),
          moment().subtract(4, 'days'),
          moment().subtract(3, 'days'),
          moment().subtract(2, 'days'),
          moment().subtract(1, 'days'),
          moment(),
        ];
        const dailyDifference = [16, -32, 8, -64, 256, -128, 512];
        const totalGrowth = [128, 96, 104, 40, 296, 168, 680];

        this.followersMeta.total = totalGrowth[totalGrowth.length - 1];
        this.followersMeta.today = dailyDifference[totalGrowth.length - 1];
        this.followersMeta.best = Math.max(...dailyDifference);
        this.followersMeta.average = Math.round(lodash.meanBy(dailyDifference, (p) => p));

        this.populateTweetsAnalyticsCharts({
          engagementsPerTweet,
          engagementsPerImpression,
          engagements,
          impressions,
          tweets,
          dates: tweetsDates,
        });
        this.populateFollowersAnalyticsCharts({
          dates: followersDates,
          dailyDifference,
          totalGrowth,
        });
        this.tweets = [
          {
            retweetCount: 1,
            impressions: 460,
            quoteCount: 0,
            userProfileClicks: 10,
            favoriteCount: 10,
            tweetId: `1310700627757010945`,
            replyCount: 0,
            bookmarkCount: 2,
            status: `Automate your life.\nFree up your day.`,
            time: `2020-09-29T17:43:31.000Z`,
          },
          {
            tweetId: `1309547048484704257`,
            retweetCount: 7,
            userProfileClicks: 42,
            impressions: 429,
            quoteCount: 0,
            favoriteCount: 20,
            replyCount: 0,
            bookmarkCount: 3,
            status: `Have a dream so you'll jump out of bed in the morning.\n\nHave a deadline so you'll work late to make it happen.`,
            time: `2020-09-25T17:59:23.000Z`,
          },
          {
            retweetCount: 3,
            quoteCount: 3,
            replyCount: 1,
            bookmarkCount: 4,
            userProfileClicks: 31,
            status: `If you can do one thing today, do this:\n\nBe an encourager.\nThe world has enough critics already.`,
            impressions: 412,
            tweetId: `1310636942825005058`,
            favoriteCount: 17,
            time: `2020-09-28T18:10:12.000Z`,
          },
          {
            retweetCount: 3,
            userProfileClicks: 25,
            impressions: 378,
            quoteCount: 1,
            replyCount: 1,
            bookmarkCount: 4,
            tweetId: `1309177034715922435`,
            status: `3 things you'll never regret.\n\n1. Scheduling tweets\n\n2. Automating retweets\n\n3. Plugging your products`,
            favoriteCount: 10,
            time: `2020-09-24T10:35:13.000Z`,
          },
          {
            replyCount: 2,
            retweetCount: 1,
            userProfileClicks: 14,
            impressions: 344,
            favoriteCount: 15,
            bookmarkCount: 4,
            quoteCount: 0,
            tweetId: `1303747099578728449`,
            status: `Better to fail 99 times out a 100.\n\nThan never try.\n\n 1 win could change your life.\n\nGet started and keep moving.`,
            time: `2020-09-09T18:50:11.000Z`,
          },
        ];
      },
      resetChartsData() {
        this.engagementsPerImpressionChartData = {};
        this.engagementsPerTweetChartData = {};
        this.impressionsChartData = {};
        this.tweetsChartData = {};
        this.followersTotalGrowthChartData = {};
        this.followersDailyDifferenceChartData = {};
      },
      getTweetsTableHeaderClass(param) {
        return this.tweetsOrderParam === param
          ? this.tweetsSortOrder === 'desc'
            ? 'bg-green-500 text-white'
            : 'bg-red-500 text-white'
          : 'bg-white dark-mode-bg';
      },
      updateDaysSpan(daysSpan) {
        if (this.isUserPlanStandardOrBelow(this.user.customerStatus) && [90, 365].includes(daysSpan)) {
          this.showUpgradeToPremiumPopUp = true;
          this.daysSpanAfterUpgrading = daysSpan;
          return;
        }
        this.daysSpan = daysSpan;
        if (this.canUserViewAnalytics) {
          this.resetChartsData();
          this.isUpdatingDaysSpan = true;
          if (this.analyticsToDisplay === 'tweets') {
            const tweetsPromise = controller.analytics.getTweets(this.user.uid, this.daysSpan);
            const tweetsAnalyticsPromise = controller.analytics.getTweetsAnalytics(
              this.user.uid,
              daysSpan
            );
            const promises = [tweetsPromise, tweetsAnalyticsPromise];

            return Promise.all(promises)
              .then(([tweets, tweetsAnalytics]) => {
                this.isUpdatingDaysSpan = false;
                this.$nextTick(function () {
                  this.tweets = lodash.orderBy(
                    tweets,
                    [this.tweetsOrderParam],
                    [this.tweetsSortOrder]
                  );
                  this.tweetsAnalytics = tweetsAnalytics;
                  this.populateTweetsAnalyticsCharts(this.getTweetsAnalyticsData(tweetsAnalytics));
                  this.updateTweetAnalyticsLastUpdatedAtText();
                  return Promise.resolve();
                });
              })
              .catch(this.handleError);
          } else if (this.analyticsToDisplay === 'followers') {
            return controller.analytics
              .getFollowersAnalytics(this.user.uid, this.daysSpan)
              .then((followersAnalytics) => {
                this.isUpdatingDaysSpan = false;
                this.$nextTick(function () {
                  this.followersAnalytics = followersAnalytics;
                  this.populateFollowersAnalyticsCharts(
                    this.getFollowersAnalyticsData(followersAnalytics)
                  );
                  return Promise.resolve();
                });
              })
              .catch(this.handleError);
          }
        } else {
          this.$nextTick(function () {
            this.populateSampleData();
          });
        }
      },
      reuseTweet(tweet) {
        const transformedTweet = {
          tweets: [
            {
              ...lodash.pick(tweet, ['count', 'status']),
            },
          ],
        };
        EventBus.$emit('open-new-composer', {
          post: transformedTweet,
          postType: 'twitter',
          source: {
            name: 'Analytics',
          },
        });
      },
    },
    mixins: [SwalModalMixin, CustomerStatusMixin],
    watch: {
      analyticsToDisplay() {
        this.updateDaysSpan(this.daysSpan);
      },
      isAnalyticsPagePublic(val, old) {
        if (old === null) return;
        dao.userProfile
          .toggleIsAnalyticsPagePublic(this.userProfile, val)
          .then(() => {
            this.$notify({
              type: 'success',
              message: `Your analytics is now ${val ? 'public!' : 'private.'}`,
            });
            if (val) {
              this.swalModal({
                title: 'Share your analytics publicly',
                html: `Share your Twitter analytics with the world!<br><br>
                  <em>https://app.hypefury.com/analytics/${this.userProfile.username}</em>`,
                type: 'info',
                confirmButtonText: 'Copy URL',
                showCancelButton: true,
                cancelButtonText: 'Thanks, I know',
                preConfirm: () => {
                  this.$copyText(`https://app.hypefury.com/analytics/${this.userProfile.username}`);
                  Swal.getConfirmButton().textContent = 'Copied!';
                  return false;
                },
              });
            }
          })
          .catch((error) => {
            console.error(error);
            this.$notify({
              type: 'warn',
              message: 'An error has occurred while toggling your choice.',
            });
          });
      },
    },
  };
</script>
<style>
  .sample-overlay {
    position: absolute;
    background: url('/img/SAMPLE.svg');
    height: 100%;
    width: 100%;
  }
</style>
